import { addDays } from 'date-fns'
import { GlassPreviewType, LabelType } from 'pages/viewer/model/viewerPageSlice'
import { DefectsDataFilterQuery, IActiveFilter } from 'shared/ui/table/lib/common'
import ISlide, { SlideDefectType } from 'types/ISlide'

type RefetchType = {
  /** Функция для повторного запроса аннотаций, связанных с конкретным кейсом. */
  refetchAnnotationsByCase: () => void
  /** Функция для повторного запроса данных по конкретному кейсу. */
  refetchCase: () => void
}

const ROTATION_DEGREES = {
  FULL_CIRCLE: 360,
  RIGHT_90: 90,
}

enum RotateAction {
  RotateLabel = 'rotateLabel',
  RotateAllLabels = 'rotateAllLabels',
}

export const refetch = ({ refetchAnnotationsByCase, refetchCase }: RefetchType) => {
  refetchCase()
  refetchAnnotationsByCase()
}

export const updateRotate = (objects: LabelType, action: string, slideId?: number) => {
  /** Создаем новый объект с копией старых значений */
  const updatedObjects = { ...objects }

  if (action === RotateAction.RotateLabel && slideId) {
    if (updatedObjects[slideId]) {
      const currentRotate = updatedObjects[slideId].rotate || 0
      const updatedRotate = (currentRotate + ROTATION_DEGREES.RIGHT_90) % ROTATION_DEGREES.FULL_CIRCLE

      updatedObjects[slideId] = {
        ...updatedObjects[slideId],
        rotate: updatedRotate >= 0 ? updatedRotate : ROTATION_DEGREES.FULL_CIRCLE + updatedRotate,
      }
    }
  }

  if (action === RotateAction.RotateAllLabels) {
    for (const id in updatedObjects) {
      const currentRotate = updatedObjects[id].rotate || 0
      const updatedRotate = (currentRotate + ROTATION_DEGREES.RIGHT_90) % ROTATION_DEGREES.FULL_CIRCLE

      updatedObjects[id] = {
        ...updatedObjects[id],
        rotate: updatedRotate >= 0 ? updatedRotate : ROTATION_DEGREES.FULL_CIRCLE + updatedRotate,
      }
    }
  }

  return updatedObjects
}

export const updatePreviewRotate = (objects: GlassPreviewType, slideId?: number) => {
  /** Создаем новый объект с копией старых значений */
  const updatedObjects = { ...objects }

  if (slideId) {
    const currentRotate = updatedObjects[slideId]?.previewRotate || 0
    const updatedRotate = (currentRotate + ROTATION_DEGREES.RIGHT_90) % ROTATION_DEGREES.FULL_CIRCLE

    updatedObjects[slideId] = {
      ...updatedObjects[slideId],
      previewRotate: updatedRotate >= 0 ? updatedRotate : ROTATION_DEGREES.FULL_CIRCLE + updatedRotate,
    }
  }

  return updatedObjects
}

export const getCountDefects = (slides: ISlide[]) =>
  slides.filter(
    (slide) =>
      slide.slideDefect != null && [SlideDefectType.DEFECT, SlideDefectType.FALSE_POSITIVE].includes(slide.slideDefect),
  ).length

enum FilterType {
  CHECKBOX = 'Checkbox',
  DATA_RANGE = 'DataRange',
  SINGLE_SELECT = 'SingleSelect',
}

type FiltersResult = {
  [key: string]: string | number | boolean | Array<string | number>
}

// Функция преобразования с использованием ваших типов
export const transformFilters = (arrFilters: IActiveFilter[]): FiltersResult =>
  arrFilters.reduce((result, { activeFilters, filterType, key }) => {
    // Обрабатываем фильтры типа "SingleSelect"
    if (filterType === FilterType.SINGLE_SELECT) {
      const activeFilter = activeFilters[0]
      if (activeFilter.queryValue) {
        result[key] = activeFilter.queryValue
      }
    }

    // Обрабатываем фильтры типа "Checkbox"
    if (filterType === FilterType.CHECKBOX) {
      const queryValues = activeFilters.map((f) => f.queryValue).filter((value): value is string | number => !!value)
      result[key] = queryValues
    }

    // Обрабатываем фильтры типа "DataRange"
    if (filterType === FilterType.DATA_RANGE) {
      const dateRange = activeFilters[0].dateRange
      if (dateRange) {
        const { from, to } = dateRange
        if (from && to) {
          result[DefectsDataFilterQuery.FROM] = new Date(from).toISOString()
          result[DefectsDataFilterQuery.TO] = addDays(new Date(to), 1).toISOString()
        }
      }
    }

    return result
  }, {} as FiltersResult)

export const ACTION_SHOW_PREVIEW = 'isShowPreview'
export const ACTION_SHOW_LABELS = 'isShowLabels'
export const ACTION_SHOW_NAME_SLIDE = 'isShowNameSlide'
